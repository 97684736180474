<template>
  <div class="OpenApplet">
    <wx-open-launch-weapp id="launch-btn" appid="wxece3a9a4c82f58c9"
      path="commercialize/pages/taoke-guide/index?scene=06e274c028c54179b108c0e1926d3e00">
      <!-- <script type="text/wxtag-template">
        <style>.btn { padding: 12px }</style>
    <button class="btn">打开小程序</button>
  </script> -->
    </wx-open-launch-weapp>
  </div>
</template>

<script>
export default {
  name: 'OpenApplet',
  data() {
    return {

    }
  },
  created() { },
  methods: {}
}
</script>

<style lang='less' scoped>
</style>